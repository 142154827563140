import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarComponent from './NavbarComponent/NavbarComponent';
import Modal from 'react-modal';
import CGU from './CGU/CGU';
// Définir l'élément racine pour les besoins d'accessibilité de react-modal
Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/CGU" element={<CGU />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
